<template>
  <div class="articleCon">
    <div class="articleHtml">
      <div class="articleHead">
        <h5>{{ article.name }}</h5>
        <span>{{ formatDate(article.createTime, "yyyy-MM-dd") }}</span>
      </div>
      <div class="articleSection" v-html="article.con"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getConfig"])
  },
  data() {
    return {
      article: {}
    };
  },
  created() {
    this.initConfig();
  },
  watch: {
    $route() {
      this.typeArticle = [];
      this.initConfig();
    },
    getConfig() {
      this.initConfig();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    initConfig() {
      for (let x of this.getConfig.articleList) {
        if (x.link === this.$route.query.id) {
          this.article = x;
        }
      }
    },
    formatDate(time, fmt) {
      if (!time) {
        return "---";
      }
      let date = new Date(time);
      let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        S: date.getMilliseconds() // 毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
  }
};
</script>
